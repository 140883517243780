import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import HeroBanner from "../components/hero";
import Layout from "../components/layout";
import SimpleCta from "../components/simple-cta";
import TwoColumnContentWithBottomImage from "../components/two-column-content-with-bottom-image";
import TwoColumnImageContent from "../components/two-column-image-content";

const CarbonNeutralServices = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "carbon-measured-services" }) {
				carbonNeutralServices {
					serviceBannerSection {
						bannerSectionHeading
						bannerSectionContent
						bannerSectionBackground {
							node {
								altText
								sourceUrl
							}
						}
					}
					serviceTwoColumnImageContent {
						twoColumnHeading
						twoColumnContent
						twoColumnTagline
						twoColumnCta1 {
							target
							title
							url
						}
						twoColumnCta2 {
							target
							title
							url
						}
						twoColumnImageBackgroundColor
						twoColumnImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						twoColumnVideoYoutube
						twoColumnVideo {
							node {
								altText
								sourceUrl
								mediaItemUrl
							}
						}
					}
					serviceTwoColumnBottomImage {
						twoColumnHeading
						twoColumnSubHeading
						twoColumnContent
						backgroundColor
						twoColumnButton2 {
							target
							title
							url
						}
						twoColumnButton1 {
							target
							title
							url
						}
						fullWidthBottomImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					serviceSimpleTextButtonCta {
						simpleTextButtonCtaHeading
						simpleTextButtonCtaContent
						ctaButton1 {
							target
							title
							url
						}
						ctaButton2 {
							target
							title
							url
						}
					}
				}
				seoFields {
					title
					opengraphTitle
					opengraphDescription
					metaDescription
					localBusinessSchema
					image {
						node {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, carbonNeutralServices },
	} = data;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Carbon Measured Services",
				item: {
					url: `${siteUrl}/carbon-measured-services`,
					id: `${siteUrl}/carbon-measured-services`,
				},
			},
		],
	};
	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/carbon-measured-services`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.mediaDetails.width}`,
							height: `${seoFields?.image?.node.mediaDetails.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<HeroBanner
					heading={
						carbonNeutralServices?.serviceBannerSection.bannerSectionHeading
					}
					tagline={
						carbonNeutralServices?.serviceBannerSection.bannerSectionSubHeading
					}
					content={
						carbonNeutralServices?.serviceBannerSection.bannerSectionContent
					}
					bgImage={
						carbonNeutralServices?.serviceBannerSection.bannerSectionBackground
							?.node?.sourceUrl
					}
					showUnderline={false}
					textAlign={"center"}
					columns={7}
				/>
				<TwoColumnImageContent
					heading={
						carbonNeutralServices?.serviceTwoColumnImageContent.twoColumnHeading
					}
					content={
						carbonNeutralServices?.serviceTwoColumnImageContent.twoColumnContent
					}
					tagline={
						carbonNeutralServices?.serviceTwoColumnImageContent.twoColumnTagline
					}
					btnCta1={
						carbonNeutralServices?.serviceTwoColumnImageContent.twoColumnCta1
					}
					image={
						carbonNeutralServices?.serviceTwoColumnImageContent.twoColumnImage
							?.node
					}
					bgColor={
						carbonNeutralServices?.serviceTwoColumnImageContent
							.twoColumnImageBackgroundColor
					}
					textColor={"#000"}
				/>
				<TwoColumnContentWithBottomImage
					heading={
						carbonNeutralServices?.serviceTwoColumnBottomImage.twoColumnHeading
					}
					content={
						carbonNeutralServices?.serviceTwoColumnBottomImage.twoColumnContent
					}
					tagline={
						carbonNeutralServices?.serviceTwoColumnBottomImage
							.twoColumnSubHeading
					}
					btnCta1={
						carbonNeutralServices?.serviceTwoColumnBottomImage.twoColumnButton1
					}
					btnCta2={
						carbonNeutralServices?.serviceTwoColumnBottomImage.twoColumnButton2
					}
					image={
						carbonNeutralServices?.serviceTwoColumnBottomImage
							.fullWidthBottomImage?.node
					}
					bgColor={
						carbonNeutralServices?.serviceTwoColumnBottomImage.backgroundColor
					}
				/>
				<SimpleCta
					heading={
						carbonNeutralServices?.serviceSimpleTextButtonCta
							.simpleTextButtonCtaHeading
					}
					content={
						carbonNeutralServices?.serviceSimpleTextButtonCta
							.simpleTextButtonCtaContent
					}
					btnCta1={carbonNeutralServices?.serviceSimpleTextButtonCta.ctaButton1}
				/>
			</Layout>
		</>
	);
};

export default CarbonNeutralServices;
